<template>
  <div class="container">
    <div class="content">
      <div class="content-left">
        <div class="session left1">
          <div class="list">
            <div
              class="item"
              :class="{ active: index == leftInd }"
              @click="
                () => {
                  leftInd = index;
                  leftText = item.text;
                }
              "
              v-for="(item, index) in left1"
              :key="index"
            >
              <div class="num flex-cc">{{ item.num }}</div>
              <div class="line"></div>
              <div class="text flex-cc">{{ item.text }}</div>
            </div>
          </div>
          <div class="table">
            <div
              class="caption flex-fs"
              :class="{
                bg2: leftInd == 1,
                bg3: leftInd == 2,
                bg4: leftInd == 3,
                bg5: leftInd == 4,
              }"
            >
              <div></div>
              {{ leftText }}
            </div>
            <div class="th">
              <div class="t1">标题</div>
              <div class="t2">小区名称</div>
              <div class="t3">结果</div>
              <div class="t4">公示时间</div>
              <div class="t5">参与人数</div>
            </div>
            <div class="tr" v-for="(item, index) in 14" :key="index">
              <div class="td t1">{{ leftText }}</div>
              <div class="td t2">
                {{ nameList[index] }}
              </div>
              <div class="td t3 green" v-if="index != 5 && index != leftInd">
                通过
              </div>
              <div class="td t3 red" v-else>未通过</div>
              <div class="rank t4">
                2024-05-0{{ leftInd }} 2{{ leftInd }}:{{ leftInd }}9:2{{
                  leftInd
                }}
              </div>
              <div class="rank t5">{{ 166 - leftInd * 3 - index * 3 }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-middle">
        <div class="session middle0 flex-fs">
          <div class="item">
            <div class="num flex-cc">78 <span>次</span></div>
            <div class="text">投票发起数</div>
          </div>
          <div class="item">
            <div class="num flex-cc">235,716 <span>次</span></div>
            <div class="text">业主参与数</div>
          </div>
        </div>
        <div class="session middle1" id="map"></div>
        <div class="session middle2">
          <div class="list">
            <div class="item" v-for="(item, index) in 4" :key="index">
              <div class="title">关于小区物业维修资金使用的投票</div>
              <div class="time">
                <span>麟州府-1-2-1701</span>
                <span>张勇</span>
                <span class="red" v-if="index == 0 || index == 2">反对</span>
                <span class="green" v-else>赞成</span>
                <span>2024-05-11 11:23:21</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-right">
        <div class="session right1">
          <div class="list flex-fs">
            <div class="item">
              涉及物业数: <span>12</span> <span class="small">个</span>
            </div>
            <div class="item">
              涉及小区数: <span>56</span> <span class="small">个</span>
            </div>
          </div>
          <div id="right2"></div>
          <div class="toupiao">
            <div class="list">
              <div
                class="caption flex-fs"
                :class="{
                  bg2: chartInd == 1,
                  bg3: chartInd == 2,
                  bg4: chartInd == 3,
                  bg5: chartInd == 4,
                }"
              >
                <div></div>
                {{ chartList[chartInd] }}
              </div>
              <div class="items">
                <div class="item" v-for="(item, index) in 8" :key="index">
                  <span
                    class="fixed flex-cc"
                    :class="{
                      bg2: chartInd == 1,
                      bg3: chartInd == 2,
                      bg4: chartInd == 3,
                      bg5: chartInd == 4,
                    }"
                  >
                    {{ chartList[chartInd] }}
                  </span>
                  <div class="top">{{ nameList[index] }}</div>
                  <div class="title">维修资金申请</div>
                  <div class="s-item">
                    覆盖人数: <span>{{ 1528 - chartInd }}</span>
                  </div>
                  <div class="s-item">
                    参与人数: <span>{{ 1528 - chartInd }}</span>
                  </div>
                  <div class="time flex-cc">
                    2024/05/01 - 2024/05/1{{ chartInd }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";
export default {
  name: "businessService",
  components: {},
  data() {
    return {
      leftText: "维修资金申请",
      leftInd: 0,
      tabInd: 1,
      chartInd: 0,
      chartList: ["待审核", "投票中", "投票截止", "计票完成", "结果公示"],
      nameList: [
        "佳和御园",
        "大泽响水湾",
        "凤凰城",
        "佳信铂金豪庭",
        "蓝波湾",
        "凤临嘉苑",
        "佳和御园",
        "文景新城",
        "瑞和嘉园",
        "佳信国际公馆",
        "佳和御园",
        "凤凰城",
        "阳光水岸",
        "文昌府邸",
      ],
      left1: [
        {
          num: "118",
          text: "维修资金申请",
          icon: require("@/assets/image/businessService/left1_1.png"),
        },
        {
          num: "56",
          text: "业委会选举",
          icon: require("@/assets/image/businessService/left1_2.png"),
        },
        {
          num: "108",
          text: "老旧小区改造",
          icon: require("@/assets/image/businessService/left1_2.png"),
        },
        {
          num: "35",
          text: "充电车房建设",
          icon: require("@/assets/image/businessService/left1_2.png"),
        },
        {
          num: "24",
          text: "其他事项",
          icon: require("@/assets/image/businessService/left1_2.png"),
        },
      ],

      left2: [
        {
          text: "物业服务未到位",
          value: 1565,
        },
        {
          text: "公共收费不真实",
          value: 1456,
        },
        {
          text: "绿化管理未到位",
          value: 1358,
        },
        {
          text: "未满意事项名称",
          value: 1246,
        },
        {
          text: "未满意事项名称",
          value: 1125,
        },
        {
          text: "未满意事项名称",
          value: 897,
        },
        {
          text: "未满意事项名称",
          value: 456,
        },
        {
          text: "未满意事项名称",
          value: 356,
        },
      ],

      right2: [
        {
          text: "物业服务态度差",
          value: 75,
        },
        {
          text: "乱收费",
          value: 69,
        },
        {
          text: "设备设施不维护",
          value: 65,
        },
        {
          text: "投诉事项名称",
          value: 57,
        },
        {
          text: "投诉事项名称",
          value: 52,
        },
        {
          text: "投诉事项名称",
          value: 42,
        },
        {
          text: "投诉事项名称",
          value: 22,
        },
        {
          text: "投诉事项名称",
          value: 12,
        },
      ],
    };
  },
  mounted() {
    this.drawLine();
    this.map();
  },
  methods: {
    map() {
      AMapLoader.load({
        key: "d1b569a4b168e12da5a06d9a5aeb6703",
        version: "2.0",
        plugins: [
          "AMap.Geolocation",
          "AMap.PlaceSearch",
          "AMap.Scale",
          "AMap.OverView",
          "AMap.ToolBar",
          "AMap.MapType",
          "AMap.PolyEditor",
          "AMap.CircleEditor",
        ],
      })
        .then((AMap) => {
          const map = new AMap.Map("map", {
            // eslint-disable-line no-unused-vars

            zoom: 14,
            center: [116.069015, 35.394718],
          });

          // 假设您已经初始化了高德地图API，并且有一个有效的地图实例map

          // 标记的位置数组
          var positions = [
            [116.069015, 35.394718], // 位置1 - 经度, 纬度
            [116.057271, 35.390532], // 位置2
            [116.082514, 35.396637], // 位置3
            // 添加更多位置
          ];

          // 创建标记
          var markers = positions.map(function (position, index) {
            var marker = new AMap.Marker({
              position: position,
              map: map, // 指定将标记添加到哪个地图实例
              title: "标记" + index, // 自定义标题
            });
            // 可以添加更多选项，如icon等
            return marker;
          });

          // 将所有标记添加到地图
          markers.forEach(function (marker) {
            marker.setMap(map);
          });

          // 进行进一步的地图操作...
        })
        .catch((e) => {
          console.log(e);
        });
    },
    drawLine() {
      // 基于准备好的dom，初始化echarts实例

      const right2 = this.$echarts.init(document.getElementById("right2"));

      right2.setOption({
        legend: {
          orient: "vertical",
          icon: "circle",
          left: "78%", // 设置图例居中
          top: "15%", // 设置图例在底部
          itemWidth: 12, // 设置图例标记的宽度
          itemHeight: 8, // 设置图例标记的高度
          triggerOnClick: "normal",
          textStyle: {
            color: "#999999",
          },
        },

        series: [
          {
            type: "pie",

            center: ["40%", "55%"],
            radius: "55%",
            itemStyle: {
              color: function (params) {
                // 定义一个颜色数组
                var colors = [
                  "#00FBFF",
                  "#FF9356",
                  "#9664FF",
                  "#E964FF",
                  "#1D9DF3",
                ];
                // 返回每个扇区的颜色，可以按照其他逻辑设置颜色
                return colors[params.dataIndex % colors.length];
              },
            },
            label: {
              formatter: " {per|{d}%}  ",

              rich: {
                b: {
                  color: "#4C5058",
                  fontSize: 14,
                  fontWeight: "bold",
                  lineHeight: 33,
                },
                per: {
                  color: "#fff",
                },
              },
            },
            data: [
              {
                value: 15,
                name: "待审核：15",
              },
              {
                value: 35,
                name: "投票中：12",
              },
              { value: 20, name: "投票截止：20" },
              { value: 30, name: "计票完成：23" },
              { value: 30, name: "结果公示：23" },
            ],
          },
        ],
      });

      // 绑定点击事件处理函数
      let _this = this;
      right2.on("click", function (params) {
        // 控制台输出点击的数据的信息

        _this.chartInd = params.dataIndex;
      });
      right2.on("legendselectchanged", function (params) {
        // params 包含了点击的 legend 的相关信息
        // 你可以在这里添加自定义的事件处理逻辑
        console.log("Legend item clicked:", params);
        if(params.name == '投票中：12'){
          _this.chartInd = 1
        }else if(params.name == '投票截止：20'){
          _this.chartInd = 2
        }else if(params.name == '计票完成：23'){
          _this.chartInd = 3
        }else if(params.name == '结果公示：23'){
          _this.chartInd = 4
        }else{
          _this.chartInd =0
        }
        right2.setOption({
          legend: { selected: { [params.name]: true } },
        });
      });
    },
    tabSwitch(val) {
      this.tabInd = val;
    },
  },
};
</script>

<style lang="less" scoped>
.red {
  color: #ff7b7b !important;
}
.green {
  color: #00e291 !important;
}
.container {
  .content {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &-left {
      width: 420px;
      margin: 0 20px;
      .session {
        margin-bottom: 20px;
      }
      .left1 {
        background-image: url(../assets/image/electronicVoting/left1.png);
        background-size: 100% 100%;
        padding-top: 56px;
        height: 940px;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        .list {
          overflow: hidden;
        }
        .item {
          float: left;
          margin-left: 20px;
          margin-bottom: 20px;
          width: 180px;
          height: 100px;
          font-size: 14px;
          text-align: center;
          cursor: pointer;
          background-size: 100% 100%;
          .num {
            height: 60px;
            font-size: 24px;
          }
          .line {
            height: 8px;
          }
          .text {
            height: 32px;
            opacity: 0.7;
            color: #fff;
          }
          &:nth-child(1) {
            background-image: url(../assets/image/electronicVoting/bg1.png);
            .num {
              color: #1d9df3;
            }
            .line {
              background: #1d9df3;
              width: 80%;
            }
            .text {
              background: rgba(29, 157, 243, 0.2);
            }
          }
          &:nth-child(2) {
            background-image: url(../assets/image/electronicVoting/bg2.png);
            .num {
              color: #ff9356;
            }
            .line {
              background: #ff9356;
              width: 95%;
            }
            .text {
              background: rgba(255, 147, 86, 0.2);
            }
          }
          &:nth-child(3) {
            background-image: url(../assets/image/electronicVoting/bg3.png);
            .num {
              color: #00fbff;
            }
            .line {
              background: #00fbff;
              width: 90%;
            }
            .text {
              background: rgba(0, 251, 255, 0.2);
            }
          }
          &:nth-child(4) {
            background-image: url(../assets/image/electronicVoting/bg4.png);
            .num {
              color: #9664ff;
            }
            .line {
              background: #9664ff;
              width: 92%;
            }
            .text {
              background: rgba(150, 100, 255, 0.2);
            }
          }
          &:nth-child(5) {
            background-image: url(../assets/image/electronicVoting/bg5.png);
            .num {
              color: #00e291;
            }
            .line {
              background: #00e291;
              width: 95%;
            }
            .text {
              background: rgba(0, 226, 145, 0.2);
            }
          }
          &.active {
            .num {
              color: #fff !important;
            }
            .text {
              opacity: 1;
            }
            .line {
              background: #fff !important;
            }
            &:nth-child(1) {
              background-image: url(../assets/image/electronicVoting/bg1_1.png);

              .text {
                background: rgba(29, 157, 243, 1);
              }
            }
            &:nth-child(2) {
              background-image: url(../assets/image/electronicVoting/bg2_1.png);

              .text {
                background: rgba(255, 147, 86, 1);
              }
            }
            &:nth-child(3) {
              background-image: url(../assets/image/electronicVoting/bg3_1.png);

              .text {
                background: rgba(0, 251, 255, 1);
              }
            }
            &:nth-child(4) {
              background-image: url(../assets/image/electronicVoting/bg4_1.png);

              .text {
                background: rgba(150, 100, 255, 1);
              }
            }
            &:nth-child(5) {
              background-image: url(../assets/image/electronicVoting/bg5_1.png);

              .text {
                background: rgba(0, 226, 145, 1);
              }
            }
          }
        }
        .table {
          .caption {
            padding-top: 8px;
            padding-bottom: 4px;
            color: rgba(29, 157, 243, 1);
            font-size: 16px;
            div {
              width: 0;
              height: 0;
              border: 7px solid rgba(29, 157, 243, 1);
              border-top-color: transparent;
              border-bottom-color: transparent;
              border-right-color: transparent;
              margin-right: 6px;
            }
            &.bg2 {
              color: #ff7e21;
              div {
                border-left-color: #ff7e21;
              }
            }
            &.bg3 {
              color: #00fbff;
              div {
                border-left-color: #00fbff;
              }
            }
            &.bg4 {
              color: #9664ff;
              div {
                border-left-color: #9664ff;
              }
            }
            &.bg5 {
              color: #00e291;
              div {
                border-left-color: #00e291;
              }
            }
          }
          line-height: 32px;
          .th {
            height: 32px;
            text-align: center;
            background: rgba(29, 157, 243, 0.3);
            color: #bce1f2;
            font-size: 13px;
            box-shadow: inset 0px -20px 30px 0px rgba(16, 157, 245, 0.2);
            div {
              float: left;
            }
          }
          .tr {
            height: 32px;
            font-size: 12px;
            text-align: center;
            div {
              float: left;
              color: rgba(255, 255, 255, 0.8);
            }

            &:nth-child(2n) {
              width: 420px;
              background: rgba(29, 157, 243, 0.1);
              box-shadow: inset 0px -20px 30px 0px rgba(16, 157, 245, 0.2);
            }
          }

          .t1 {
            width: 18%;
          }
          .t2 {
            width: 18%;
          }
          .t3 {
            width: 10%;
          }
          .t4 {
            width: 36%;
          }
          .t5 {
            width: 18%;
          }
        }
      }
    }
    &-middle {
      width: 1000px;
      .session {
        margin-bottom: 20px;
      }
      .middle0 {
        height: 180px;
        background-size: 100% 100%;
        background-image: url(../assets/image/electronicVoting/middle0_bg.png);
        .item {
          height: 140px;
          width: 215px;
          background-size: 100% 100%;
          margin-left: 190px;
          text-align: center;
          .num {
            font-size: 32px;
            color: #00fbff;
            height: 76px;
            span {
              font-size: 14px;
              opacity: 0.7;
              padding-left: 6px;
            }
          }
          .text {
            color: #fff;
            font-size: 14px;
          }
          &:nth-child(1) {
            background-image: url(../assets/image/electronicVoting/middle0_1.png);
            .num {
              color: #1d9df3;
            }
          }
          &:nth-child(2) {
            background-image: url(../assets/image/electronicVoting/middle0_2.png);
          }
        }
      }
      .middle1 {
        height: 480px;
        text-align: center;
      }
      .middle2 {
        height: 240px;
        background-image: url(../assets/image/electronicVoting/middle3.png);
        background-size: 100% 100%;
        padding-top: 36px;
        .list {
          flex-wrap: wrap;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 20px 20px 0;
        }
        .item {
          width: 475px;
          height: 78px;
          padding: 12px;

          margin-bottom: 10px;
          background: rgba(29, 157, 243, 0.1);
          .title {
            font-size: 16px;
            color: #ffffff;
            margin-bottom: 8px;
          }
          .time {
            span {
              margin-right: 28px;
            }
            font-size: 14px;
            color: #bce1f2;
          }
        }
      }
    }
    &-right {
      width: 420px;
      margin: 0 20px;
      .session {
        margin-bottom: 20px;
        height: 300px;
      }
      #right2 {
        height: 240px;
      }
      .right1 {
        background-image: url(../assets/image/electronicVoting/right1.png);
        background-size: 100% 100%;
        height: 940px;
        padding-top: 56px;
        padding-right: 20px;
        font-size: 14px;
        color: #fff;
        overflow: hidden;
        & > .list {
          .item {
            width: 180px;
            height: 27px;
            line-height: 27px;
            background: rgba(255, 126, 33, 0.2);
            color: #fff;
            font-size: 12px;
            padding: 0 8px;
            margin-left: 20px;
            text-align: right;
            span {
              font-size: 16px;
              color: #ff7e21;
              &.small {
                font-size: 12px;
                opacity: 0.7;
              }
            }
            &:nth-child(1) {
              background: rgba(29, 157, 243, 0.2);
              text-align: left;
              span {
                color: #1d9df3;
              }
            }
          }
        }
        .toupiao {
          padding-top: 35px;
          .caption {
            padding-top: 8px;
            padding-bottom: 4px;
            color: #00fbff;
            font-size: 16px;
            div {
              width: 0;
              height: 0;
              border: 7px solid #00fbff;
              border-top-color: transparent;
              border-bottom-color: transparent;
              border-right-color: transparent;
              margin-right: 6px;
            }
            &.bg2 {
              color: #ff7e21;
              div {
                border-left-color: #ff7e21;
              }
            }
            &.bg3 {
              color: #9664ff;
              div {
                border-left-color: #9664ff;
              }
            }
            &.bg4 {
              color: #e964ff;
              div {
                border-left-color: #e964ff;
              }
            }
            &.bg5 {
              color: #1d9df3;
              div {
                border-left-color: #1d9df3;
              }
            }
          }
          .items {
            height: 548px;
            &::-webkit-scrollbar {
              display: none; /* 针对WebKit浏览器隐藏滚动条 */
            }

            -ms-overflow-style: none; /* 针对IE和Edge隐藏滚动条 */

            overflow: auto;
            padding-left: 20px;
            flex-wrap: wrap;
            padding-top: 12px;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .item {
            position: relative;
            width: 185px;
            height: 173px;
            margin-bottom: 10px;
            background: rgba(29, 157, 243, 0.1);

            .fixed {
              background-size: 100% 100%;
              background-image: url(../assets/image/electronicVoting/rbg1.png);
              position: absolute;
              left: 0;
              top: 0;
              width: 54px;
              height: 24px;
              color: #333;
              border-radius: 0px 0px 12px 0px;

              &.bg2 {
                background-image: url(../assets/image/electronicVoting/rbg2.png);
              }
              &.bg3 {
                width: 64px;
                background-image: url(../assets/image/electronicVoting/rbg3.png);
              }
              &.bg4 {
                width: 64px;
                background-image: url(../assets/image/electronicVoting/rbg4.png);
              }
              &.bg5 {
                width: 64px;
                background-image: url(../assets/image/electronicVoting/rbg5.png);
              }
            }
            font-size: 12px;
            color: #fff;
            .top {
              text-align: right;
              padding-top: 3px;
              padding-bottom: 15px;
              padding: 5px 8px 0;
            }
            .title {
              font-size: 14px;
              padding: 18px 8px 0;
            }
            .s-item {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin: 8px 8px 0;
              width: 169px;
              padding-left: 8px;
              height: 32px;
              background: linear-gradient(
                270deg,
                rgba(29, 157, 243, 0) 0%,
                #1d9df3 100%
              );
              color: #bce1f2;
              span {
                color: #fff;
                font-size: 14px;
              }
            }
            .time {
              margin-top: 12px;
              width: 185px;
              height: 24px;
              background: rgba(29, 157, 243, 0.3);
            }
          }
        }
      }
    }
  }
}
</style>
