export default {
  data() {
      return {
          width: 1920,
          height: 1080,
      }
  },
  mounted() {
      this.autoResizeScreen();
      window.addEventListener('resize', this.autoResizeScreen);
  },
  methods: {
      autoResizeScreen() {
          let rect = this.$refs.AutoScalContainerRef.getBoundingClientRect()
          let DomRef = this.$refs.DomRef
          let clientWidth = rect.width

          let clientHeight = rect.height
          var width = this.width
          var height = this.height
          let left = 0
          let top = 0
          let scale = 0
         // let ratio = width / height;
        //   if ((clientWidth / clientHeight) > ratio) {
        //       scale = clientHeight / height;
        //       top = 0;
        //       left = (clientWidth - width * scale) / 2;
        //   } else {
        //       scale = clientWidth / width;
        //       left = 0;
        //       top = (clientHeight - height * scale) / 2;
        //   }
          scale = clientWidth / width;
          console.log("🚀 ~ autoResizeScreen ~ scale:", scale,clientHeight*scale)
          left = 0;
          top = (clientHeight - height * scale) / 2;
          Object.assign(DomRef.style, {
              transform: `scale(${scale})`,
              left: `${left}px`,
              top: `${top}px`,
          });
          document.getElementById('app').style.height = `${clientHeight*scale}px`
          document.getElementById('app').style.overflow = `hidden`

      }
  },
  beforeDestroy() {
      window.removeEventListener('resize', this.autoResizeScreen);
  },
}
