<template>
  <div class="header" :class="{ home: path == '/' }">
    <div class="nav-bar">
      <router-link
        v-for="(item, index) in list"
        :key="index"
        :to="{ path: item.path }"
        class="nav-item flex-cc"
        :class="path === item.path ? 'select' + (index + 1) : ''"
      ></router-link>
    </div>
    <div class="logo">
      <img src="@/assets/image/logo.png" alt="" />
    </div>
    <div class="date">
      <p>{{ date1 }}</p>
      <p>{{ date2 }}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "HeaderBar",
  data() {
    return {
      date1: "",
      date2: "",
      path: "/",
      list: [
        {
          name: "首页",
          path: "/",
          icon: require("@/assets/image/nav/nav1.png"),
        },
        {
          name: "维修资金",
          path: "/maintenance",
          icon: require("@/assets/image/nav/nav2.png"),
        },
        {
          name: "物业服务动态",
          path: "/propertyService",
          icon: require("@/assets/image/nav/nav3.png"),
        },
        {
          name: "业务服务管理",
          path: "/businessService",
          icon: require("@/assets/image/nav/nav4.png"),
        },
        // {
        //   name: "平安建设",
        //   path: "/construction",
        //   icon: require("@/assets/image/nav/nav5.png"),
        // },
        {
          name: "电子投票",
          path: "/electronicVoting",
          icon: require("@/assets/image/nav/nav5.png"),
        },
      ],
    };
  },
  mounted() {
    this.path = this.$route.path;
    setInterval(() => {
      this.getDate();
    }, 1000);
  },
  methods: {
    getDate() {
      const today = new Date();
      let date1 = `${today.getFullYear()}-${
        today.getMonth() + 1 < 10
          ? "0" + (today.getMonth() + 1)
          : today.getMonth() + 1
      }-${today.getDate() < 10 ? "0" + today.getDate() : today.getDate()}`;
      this.date1 = date1;
      const currentDate = new Date();
      const weekDays = [
        "星期天",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ];
      const currentDay = currentDate.getDay();
      const hours = today.getHours().toString().padStart(2, "0");
      const minutes = today.getMinutes().toString().padStart(2, "0");
      const seconds = today.getSeconds().toString().padStart(2, "0");

      this.date2 = `${weekDays[currentDay]} ${hours}:${minutes}:${seconds}`;
    },
  },
  watch: {
    // 监听路由对象$route的变化
    $route: {
      handler: function (to, from) {
        // 路由发生变化时执行的代码
        this.path = to.path;
        console.log("Route changed from", from.path, "to", to.path);
      },
      // 设置为深度监听
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
a {
  text-decoration: none;
}
.header {
  position: relative;
  height: 100px;
  z-index: 9999;
  background-image: url(../assets/image/header_bg.png);
  background-size: contain;
  &.home {
    background-image: url(../assets/image/header_bg1.png);
  }
  .nav-bar {
    padding-left: 20px;
    padding-top: 19px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .nav-item {
      cursor: pointer;
      width: 239px;
      height: 45.5px;
      margin-top: 11px;
      color: #fff;

      background-size: 100% 100%;
      font-weight: bold;
      margin-left: -25px;
      &:nth-child(1) {
        background-image: url(../assets/image/nav/nav1.png);
        margin-left: 0;
      }
      &:nth-child(1):hover {
        background-image: url(../assets/image/nav/nav1_1.png);
      }
      &:nth-child(2) {
        background-image: url(../assets/image/nav/nav2.png);
      }
      &:nth-child(3) {
        background-image: url(../assets/image/nav/nav3.png);
      }
      &:nth-child(4) {
        background-image: url(../assets/image/nav/nav4.png);
        margin-left: 545px;
      }
      &:nth-child(5) {
        background-image: url(../assets/image/nav/nav5.png);
      }
      &.select1 {
        background-image: url(../assets/image/nav/nav1_1.png) !important;
      }
      &.select2 {
        background-image: url(../assets/image/nav/nav2_1.png) !important;
      }
      &.select3 {
        background-image: url(../assets/image/nav/nav3_1.png) !important;
      }
      &.select4 {
        background-image: url(../assets/image/nav/nav4_1.png) !important;
        margin-left: 545px;
      }
      &.select5 {
        background-image: url(../assets/image/nav/nav5_1.png) !important;
      }
      &:nth-child(2):hover {
        background-image: url(../assets/image/nav/nav2_1.png);
      }
      &:nth-child(3):hover {
        background-image: url(../assets/image/nav/nav3_1.png);
      }
      &:nth-child(4):hover {
        background-image: url(../assets/image/nav/nav4_1.png);
        margin-left: 545px;
      }
      &:nth-child(5):hover {
        background-image: url(../assets/image/nav/nav5_1.png);
      }
    }
  }
  .logo {
    width: 618px;

    height: 79px;
    border-radius: 0px 0px 0px 0px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-309px);
    text-align: center;
    img {
      margin-top: 18px;
      width: 338px;
    }
  }
  .date {
    position: absolute;
    top: 0;
    right: 0;
    padding-top: 11px;
    padding-right: 20px;
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    line-height: 20px;
  }
}
</style>
